<template>
  <BtnTemplate
    class="main"
    :class="{ disabled, small }"
    :is-link="isLink"
    :data-test-id="dataTestId"
    v-bind="$attrs"
    @click="handleClick"
  >
    <span class="btnText">
      <slot />
    </span>
  </BtnTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import BtnTemplate from './template.vue'

export default defineComponent({
  components: {
    BtnTemplate,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataTestId: {
      type: String as PropType<string | undefined>,
      required: false,
      default: undefined,
    },
  },
  emits: ['click'],
  methods: {
    handleClick(event: Event): void {
      if (!this.disabled) this.$emit('click', event)
    },
  },
})
</script>
